@import '../../variables.less';

.login-page-layout {
  min-height: 100vh;
  //background-color: @dark-color !important;
  //background-size: cover !important;
  //background-position: center !important;
  background-color: white !important;
  overflow: hidden;

  .row {
    min-height: 100vh;
  }

  .logo {
    width: 80%;
    height: auto;
    margin: 16px auto;
  }
}

.login-form {
  max-width: 300px;

  .login-form-forgot {
    float: right;
  }

  .login-form-register {
    text-align: center;
    width: 100%;
    display: block;
  }
}

