

.admin-console-image-wrapper {
  overflow: hidden;
  margin: auto;
}

.admin-console-image {
  margin-left: auto;
  margin-right: auto;
  display: block !important;
  padding: 20px;
}

.admin-console-col {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 300px;
}

.admin-scaled-card {
  height: 100%;
}
