// Imports
@import "../../../index";

// CSS
.drawer {
    overflow: auto;
    height: 100vh;
    position: fixed !important;
    left: 0;
    z-index: 999;
    border-right: 1px solid #f0f0f0;

    .ant-menu {
        border-right: none !important;
    }
}

.drawer .open-button {
    position: fixed;
    height: 32px;
    width: 32px;
    background: @dark-color;
    top: 45px;
    left: 200px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: left 0.2s ease;
    display: none
}

.drawer .open-button .open-button-icon {
    font-size: 18px;
    color: white;
    margin: 6px auto;
    display: block;
}

.drawer .logo {
    background: white;
    padding: 5%;
    margin: 5%;
    border-radius: 2px;
}

.drawer .logo img {
    width: 90%;
    height: auto;
    margin: 0 auto;
    display: block;
}

.drawer-overlay {
    background: black;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 998;
    opacity: 0;
    display: none;
    transition: opacity 0.2s ease;
}

.drawer-menu-divider {
    width: 80% !important;
    margin: 0 auto !important;
    background-color: @light-color !important;
}

@media screen and (max-width: 1600px) { /* xxl */ }

@media screen and (max-width: 1200px) { /* xl */ }

@media screen and (max-width: 992px) { /* lg */
    .drawer {
        left: -200px;
    }

    .drawer .open-button {
        display: block;
        left: 0;
    }

    .drawer.open {
        left: 0;
    }

    .drawer.open .open-button {
        left: 200px
    }

    .drawer-overlay.show {
        display: block;
        opacity: 0.6;
    }
}

@media screen and (max-width: 768px) { /* md */ }

@media screen and (max-width: 576px) { /* sm */ }

@media screen and (max-width: 480px) { /* xs */ }

