.drawer-layout {
    margin-left: 200px;
}

@media screen and (max-width: 1600px) { /* xxl */ }

@media screen and (max-width: 1200px) { /* xl */ }

@media screen and (max-width: 992px) { /* lg */
    .drawer-layout {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) { /* md */ }

@media screen and (max-width: 576px) { /* sm */ }

@media screen and (max-width: 480px) { /* xs */ }
