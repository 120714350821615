.fontawesome-margin-right-10px {
    margin-right: 10px;
}

.fontawesome-margin-left-10px {
    margin-left: 10px;
}

.fontawesome-centre-align {
    display:inline-block;
    text-align: center;
    vertical-align: bottom;
}
